<template>
  <div>
    <div class="card" :class="$style.container">
      <div class="text-dark font-size-32 mb-3">Inicio de Sesión</div>
      <my-spinner :load="loading"/>
      <router-link to="/auth/forgot-password" class="kit__utils__link font-size-16">¿Olvidaste tu contraseña?</router-link>
    </div>
    <div class="text-center pt-2 mb-auto">
      <span class="mr-2">¿No tienes una cuenta?</span>
      <router-link to="/auth/register" class="kit__utils__link font-size-16">Registrarse</router-link>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'login',
  computed: {
    ...mapState(['settings']),
    loading() {
      return this.$store.state.user.loading
    },
  },
  data: function () {
    return {
      code: this.$route.query.code,
      provider: this.$route.params.provider,
    }
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          this.$store.dispatch('user/LOGIN', { payload: values })
        }
      })
    },
  },
  mounted() {
    this.$store.dispatch('user/SOCIALITE_LOGIN_CALLBACK', {
      payload: {
        provider: this.provider,
        code: this.code,
      },
    })
  },
}
</script>
<style lang="scss" module>
  @import "@/@airui/system/Auth/style.module.scss";
</style>
